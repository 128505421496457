import { useState } from 'react';
import { LoanStatusChangeEmailsRepository } from '../../domain/repositories';
import { LoanStatusChangeEmail } from '../../domain/models';
import { Alert } from '../../utils';
import { RestLoanStatusChangeEmailsRepository } from '../../infrastructure/repositories';

const useLoanStatusChangeEmails = () => {
  const [loading, setLoading] = useState(false);
  const [loanStatusChangeEmails, setLoanStatusChangeEmails] = useState<LoanStatusChangeEmail[]>([]);

  const loanStatusChangeRepository: LoanStatusChangeEmailsRepository = RestLoanStatusChangeEmailsRepository.getInstance();

  const loadLoanStatusChangeEmails = async () => {
    setLoading(true);
    const [loanStatusChangeEmails, errorMessage] = await loanStatusChangeRepository.getEmails();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoanStatusChangeEmails(loanStatusChangeEmails!);
    }
  };

  return {
    loading,
    loadLoanStatusChangeEmails,
    loanStatusChangeEmails,
  };
};

export default useLoanStatusChangeEmails;