import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { Bank } from "../../../domain/models";
import { BankMapper } from "./mappers";
import { BanksRepository } from "../../../domain/repositories";
import { BankEntity } from "./entities";
import { LocalStorageTokenRepository } from "..";

export default class RestBanksRepository implements BanksRepository {
  private static instance: RestBanksRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestBanksRepository();
    }
    return this.instance;
  }

  async getBanks(): Promise<[Bank[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<BankEntity[]>(`${Environment.backendUrl}/admin/v1/banks?t=${token}`);
      const banks = response.data.map((bankEntity) => BankMapper.toModel(bankEntity));

      return [banks, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async createBank(name: string, code: string): Promise<[Bank?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.post<BankEntity>(`${Environment.backendUrl}/admin/v1/banks?t=${token}`, {
        name, code
      });
      const bank = BankMapper.toModel(response.data);

      return [bank, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}