import { Constants } from "../../utils";
import { CreditCapacity } from "./";

interface IUser {
  id: string;
  createdAt: Date;
  email: string;
  firstName?: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  birthDate?: Date;
  mobilePhone?: string;
  phone?: string;
  genderCd?: number;
  maritalStatusCd?: number;
  documentNumber?: string;
  documentExpeditionDate?: Date;
  documentTypeCd?: number;
  creditCapacity?: CreditCapacity;
}

export default class User {
  id: string;
  createdAt: Date;
  email: string;
  firstName?: string;
  secondName?: string;
  firstSurname?: string;
  secondSurname?: string;
  birthDate?: Date;
  mobilePhone?: string;
  phone?: string;
  genderCd?: number;
  maritalStatusCd?: number;
  documentNumber?: string;
  documentExpeditionDate?: Date;
  documentTypeCd?: number;
  creditCapacity?: CreditCapacity;

  constructor({
    id, createdAt,
    email, firstName, secondName,
    firstSurname, secondSurname,
    birthDate, mobilePhone,
    phone, genderCd,
    maritalStatusCd, documentNumber,
    documentExpeditionDate, documentTypeCd,
    creditCapacity
  }: IUser) {
    this.id = id;
    this.createdAt = createdAt;
    this.email = email;
    this.firstName = firstName;
    this.secondName = secondName;
    this.firstSurname = firstSurname;
    this.secondSurname = secondSurname;
    this.birthDate = birthDate;
    this.mobilePhone = mobilePhone;
    this.phone = phone;
    this.genderCd = genderCd;
    this.maritalStatusCd = maritalStatusCd;
    this.documentNumber = documentNumber;
    this.documentExpeditionDate = documentExpeditionDate;
    this.documentTypeCd = documentTypeCd;
    this.creditCapacity = creditCapacity;
  }

  get fullName() {
    return `${this.firstName} ${this.secondName ?? ''} ${this.firstSurname ?? ''} ${this.secondSurname ?? ''}`;
  }

  get shortDocumentType() {
    switch (this.documentTypeCd) {
      case Constants.DOCUMENT_TYPES['Cedula de ciudadania']:
        return 'cc';
      case Constants.DOCUMENT_TYPES['Cedula de extranjeria']:
        return 'ce';
      default:
        throw new Error(`Unknown type: ${this.documentTypeCd}`);
    }
  }
}