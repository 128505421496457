import { useEffect } from "react";
import { HTMLInput, Input, PageContainer, Splash } from "../components"
import { useParams } from "react-router-dom";
import { usePreviewEmail } from "../hooks/";

const PreviewEmailPage = () => {
  const { id, type } = useParams();
  const {
    loading, subject, body, previewEmail
  } = usePreviewEmail(type!);

  useEffect(() => {
    previewEmail(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-4">
        <Input
          placeholder="Asunto"
          className="p-3"
          value={subject.value}
          disabled
        />

        <HTMLInput
          placeholder="Contenido"
          value={body.value}
          disabled
        />
      </div>
    </PageContainer>
  )
}

export default PreviewEmailPage;