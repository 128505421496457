//import { LoanStatus } from "../domain/models/loan";

import { LoanStatus } from "../domain/models/loan";

export default class Color {
  static loanStatusToBgColor = (status: LoanStatus): string => {
    switch (status) {
      case LoanStatus.open:
        return 'bg-amber-100';
      case LoanStatus.approved:
        return 'bg-cyan-100';
      case LoanStatus.deposited:
        return 'bg-cyan-100';
      case LoanStatus.paid:
        return 'bg-emerald-100';
      case LoanStatus.rejected:
        return 'bg-red-100';
      default:
        return '';
    }
  }
}