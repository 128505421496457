import {
  createBrowserRouter
} from "react-router-dom";
import {
  BanksPage,
  ConfigPage,
  EventEmailsPage,
  LoanDetailPage,
  LoanDocumentTemplatesPage,
  LoansPage,
  LoanStatusChangeEmailsPage,
  LoginPage,
  NewBankPage,
  NotFoundPage,
  PreviewEmailPage,
  PurposesPage,
  UpdateEventEmailPage,
  UpdateLoanDocumentTemplatePage,
  UpdateLoanStatusChangeEmailPage,
  UserDetailPage,
  UsersPage
} from "../presentation/pages";
import Routes from "./Routes";
import { PrivateRoute } from "../presentation/components";

export default createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      { path: Routes.HOME_PATH, element: <LoansPage /> },
      { path: Routes.LOANS_PATH, element: <LoansPage /> },
      { path: Routes.USERS_PATH, element: <UsersPage /> },
      { path: Routes.LOAN_DETAIL_PATH, element: <LoanDetailPage /> },
      { path: Routes.PURPOSES_PATH, element: <PurposesPage /> },
      { path: Routes.BANKS_PATH, element: <BanksPage /> },
      { path: Routes.NEW_BANK_PATH, element: <NewBankPage /> },
      { path: Routes.CONFIG_PATH, element: <ConfigPage /> },
      { path: Routes.USER_DETAIL_PATH, element: <UserDetailPage /> },
      { path: Routes.EVENT_EMAILS_PATH, element: <EventEmailsPage /> },
      { path: Routes.UPDATE_EVENT_EMAIL_PATH, element: <UpdateEventEmailPage /> },
      { path: Routes.PREVIEW_EMAIL_PATH, element: <PreviewEmailPage /> },
      { path: Routes.STATUS_EMAILS_PATH, element: <LoanStatusChangeEmailsPage /> },
      { path: Routes.UPDATE_LOAN_STATUS_CHANGE_EMAIL_PATH, element: <UpdateLoanStatusChangeEmailPage /> },
      { path: Routes.DOCUMENTS_PATH, element: <LoanDocumentTemplatesPage /> },
      { path: Routes.UPDATE_LOAN_DOCUMENT_TEMPLATE_PATH, element: <UpdateLoanDocumentTemplatePage /> },
    ],
  },
  { path: Routes.LOGIN_PATH, element: <LoginPage /> },
  { path: '*', element: <NotFoundPage /> },
]);
