export enum EventEmailType {
  loan_overdue = 'Préstamo en mora',
  payment_date = 'Hoy es la fecha de pago',
  near_payment_date = 'Fecha de pago cerca (En 3 días)',
  reset_password = 'Nueva contraseña generada por recuperación de password',
  contract_signed_received = 'Notificación de firma de contrato recibida',
  legacy_users_onboarding = 'Onboarding de usuarios de Prestándonos',
}

interface IEventEmail {
  id: string;
  createdAt: Date;
  subject: string;
  body: string;
  eventType: EventEmailType;
}

export default class EventEmail {
  id: string;
  createdAt: Date;
  subject: string;
  body: string;
  eventType: EventEmailType;
  
  constructor({
    id, createdAt,
    subject, body,
    eventType
  }: IEventEmail) {
    this.id = id;
    this.createdAt = createdAt;
    this.subject = subject;
    this.body = body;
    this.eventType = eventType;
  }
}