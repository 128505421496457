import { useState } from 'react';
import { UsersRepository } from '../../domain/repositories';
import { Bank, BankAccount, User } from '../../domain/models';
import { Alert, Constants } from '../../utils';
import { InputValidator, Number, Selector } from '../../infrastructure/inputs';
import { RestUsersRepository } from '../../infrastructure/repositories';

const useUpdateUserBankAccount = (user: User, banks: Bank[], bankAccount?: BankAccount) => {
  const [bankAccountType, setBankAccountType] = useState(Selector.dirtyWithOptions(bankAccount?.type_cd.toString(), Object.values(Constants.BANK_ACCOUNT_TYPES)));
  const [bankCode, setBankCode] = useState(Selector.dirtyWithOptions(bankAccount?.bank?.code, banks.map(bank => bank.code) ?? []));
  const [bankAccountNumber, setBankAccountNumber] = useState(Number.dirty(bankAccount?.number ?? ""));
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const updateBankAccount = async (setBankAccount: (bankAccount: BankAccount) => void) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [newBankAccount, errorMessage] = await usersRepository.updateBankAccount(
      user.id, bankCode.value, bankAccountType.getNumber()!, bankAccountNumber.value
    );
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setBankAccount(newBankAccount!);
    }
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      bankAccountType, bankCode, bankAccountNumber
    ]);
  }

  return {
    bankAccountType, setBankAccountType,
    bankCode, setBankCode,
    bankAccountNumber, setBankAccountNumber,
    submitted, updateBankAccount,
    loading
  };
};

export default useUpdateUserBankAccount;