import { useState } from "react";
import { Bank } from "../../domain/models";
import { Alert } from "../../utils";
import { RestBanksRepository } from "../../infrastructure/repositories";
import { BanksRepository } from "../../domain/repositories";

const useBanks = () => {
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);

  const banksRepository: BanksRepository = RestBanksRepository.getInstance();

  const loadBanks = async () => {
    setLoading(true);
    const [obtainedBanks, errorMessage] = await banksRepository.getBanks();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setBanks(obtainedBanks!);
    }
    setLoading(false);
  }

  return {
    loading, setLoading,
    banks, setBanks,
    loadBanks
  };
};

export default useBanks;