import { useState } from 'react';
import { LoanDocumentTemplatesRepository } from '../../domain/repositories';
import { Alert, Constants } from '../../utils';
import { RestLoanDocumentTemplatesRepository } from '../../infrastructure/repositories';
import { InputValidator, Text } from '../../infrastructure/inputs';
import { LoanDocumentTemplateType } from '../../domain/models/loan_document_template';

const useUpdateLoanDocumentTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<LoanDocumentTemplateType | undefined>(undefined);
  const [template, setTemplate] = useState(Text.dirty(''));
  const [submitted, setSubmitted] = useState(false);

  const loanDocumentTemplateRepository: LoanDocumentTemplatesRepository = RestLoanDocumentTemplatesRepository.getInstance();

  const loadLoanDocumentTemplate = async (id: string) => {
    setLoading(true);
    const [loanDocumentTemplate, errorMessage] = await loanDocumentTemplateRepository.getEmail(id);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setTemplate(Text.dirty(loanDocumentTemplate!.template));
      setType(loanDocumentTemplate?.type)
    }
  }

  const updateLoanDocumentTemplate = async (id: string) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [loanDocumentTemplate, errorMessage] = await loanDocumentTemplateRepository.updateTemplate(id, template.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      Alert.showSuccess(Constants.UPDATED_TEMPLATE_CORRECTLY_MSG);
      setTemplate(Text.dirty(loanDocumentTemplate!.template));
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([template]);
  }

  return {
    loading,
    template, setTemplate,
    submitted, updateLoanDocumentTemplate,
    loadLoanDocumentTemplate, type
  };
};

export default useUpdateLoanDocumentTemplate;