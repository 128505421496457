interface IBank {
  id: string;
  name: string;
  code: string;
}

export default class Bank {
  id: string;
  name: string;
  code: string;

  constructor({ id, name, code }: IBank) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}