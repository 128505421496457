import { useState } from "react";
import { CheckboxInput, Date, MoneyValue, Number, Selector } from "../../infrastructure/inputs";
import { Loan } from "../../domain/models";
import { Constants, DateUtil, Money } from "../../utils";

const useLoanInfo = () => {
  const [amountApproved, setAmountApproved] = useState(MoneyValue.dirty(''));
  const [creditScore, setCreditScore] = useState(Selector.dirtyWithOptions('', Object.values(Constants.CREDIT_SCORES)));
  const [status, setStatus] = useState(Selector.dirtyWithOptions('', Loan.loanStatusCds()));
  const [depositedAt, setDepositedAt] = useState(Date.dirty(""));
  const [paymentMethod, setPaymentMethod] = useState(Selector.dirtyWithOptions('', Object.values(Constants.PAYMENT_METHODS)));
  const [annualEffectiveInterestRate, setAnnualEffectiveInterestRate] = useState(Number.dirty(""));
  const [estimatedPaymentDate, setEstimatedPaymentDate] = useState(Date.dirty(""));
  const [paidAt, setPaidAt] = useState(Date.dirty(""));
  const [amountPaid, setAmountPaid] = useState(MoneyValue.dirty(""));
  const [negotiatedAmountToPay, setNegotiatedAmountToPay] = useState(MoneyValue.dirty(""));
  const [negotiatedPaymentDate, setNegotiatedPaymentDate] = useState(Date.dirty(""));
  const [acceptedOnlineSignature, setAcceptedOnlineSignature] = useState(CheckboxInput.dirty(""));
  const [signedContract, setSignedContract] = useState(CheckboxInput.dirty(""));

  const loadInitialLoanInfo = (loan: Loan) => {
    setStatus(Selector.dirtyWithOptions(Loan.enumToLoanStatusCd(loan.status), Loan.loanStatusCds()));
    setCreditScore(Selector.dirtyWithOptions(loan.creditScore, Object.values(Constants.CREDIT_SCORES)));
    setPaymentMethod(Selector.dirtyWithOptions(loan.paymentMethod ? Loan.enumToPaymentMethodCd(loan.paymentMethod) : '', Loan.paymentMethodCds()));
    setAmountApproved(MoneyValue.dirty(loan.amountApproved));
    setAnnualEffectiveInterestRate(Number.dirty(
      loan.annualEffectiveInterestRate !== undefined && loan.annualEffectiveInterestRate !== null ? loan.annualEffectiveInterestRate * 100 : ''
    ));
    setDepositedAt(Date.dirty(
      loan.depositedAt ? DateUtil.shortDate(loan.depositedAt) : ''
    ));
    setEstimatedPaymentDate(Date.dirty(
      loan.estimatedPaymentDate ? DateUtil.shortDate(loan.estimatedPaymentDate) : ''
    ));
    setPaidAt(Date.dirty(
      loan.paidAt ? DateUtil.shortDate(loan.paidAt) : ''
    ));
    setAmountPaid(MoneyValue.dirty(loan.amountPaid));
    setNegotiatedPaymentDate(Date.dirty(
      loan.negotiatedPaymentDate ? DateUtil.shortDate(loan.negotiatedPaymentDate) : ''
    ));
    setNegotiatedAmountToPay(MoneyValue.dirty(loan.negotiatedAmountToPay));
    setAcceptedOnlineSignature(CheckboxInput.dirty(String(loan.acceptedOnlineSignature)));
    setSignedContract(CheckboxInput.dirty(String(loan.signedContract)));
  }

  const buildPayload = () => {
    const amountApprovedValue = amountApproved.getMoney();
    const negotiatedAmountToPayValue = negotiatedAmountToPay.getMoney();
    const amountPaidValue = amountPaid.getMoney();
    const annualEffectiveInterestRateValue = annualEffectiveInterestRate.getNumber();

    return {
      amount_approved: amountApprovedValue ? Money.parseFromCOP(amountApprovedValue) : null,
      credit_score: creditScore.getNumber(),
      status_cd: status.getNumber(),
      deposited_at: depositedAt.getDate()?.toISOString(),
      payment_method_cd: paymentMethod.getNumber(),
      annual_effective_interest_rate: annualEffectiveInterestRateValue !== undefined && annualEffectiveInterestRateValue !== null ? annualEffectiveInterestRateValue / 100.0 : null,
      estimated_payment_date: estimatedPaymentDate.getDate()?.toISOString(),
      paid_at: paidAt.getDate()?.toISOString(),
      amount_paid: amountPaidValue ? Money.parseFromCOP(amountPaidValue) : null,
      negotiated_amount_to_pay: negotiatedAmountToPayValue ? Money.parseFromCOP(negotiatedAmountToPayValue) : null,
      negotiated_payment_date: negotiatedPaymentDate.getDate()?.toISOString(),
      accepted_online_signature: acceptedOnlineSignature.getBoolean(),
      signed_contract: signedContract.getBoolean()
    }
  }

  return {
    loadInitialLoanInfo,
    setStatus, status,
    creditScore, setCreditScore,
    amountApproved, setAmountApproved,
    depositedAt, setDepositedAt,
    paymentMethod, setPaymentMethod,
    annualEffectiveInterestRate, setAnnualEffectiveInterestRate,
    estimatedPaymentDate, setEstimatedPaymentDate,
    paidAt, setPaidAt,
    amountPaid, setAmountPaid,
    negotiatedAmountToPay, setNegotiatedAmountToPay,
    negotiatedPaymentDate, setNegotiatedPaymentDate,
    acceptedOnlineSignature, setAcceptedOnlineSignature,
    signedContract, setSignedContract,
    buildPayload,
  };
};

export default useLoanInfo;