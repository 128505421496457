import { Input, Button, SectionSelector } from "../";
import { useLoansTableFilters } from "../../hooks";
import { Date as DateInput, Number } from '../../../infrastructure/inputs';
import Loan, { LoanStatus } from "../../../domain/models/loan";
import { getLoansFilter } from "../../../domain/repositories/LoansRepository";

interface LoansTableProps {
  onSearch: (filter: getLoansFilter) => void;
  onClearFilter: () => void;
}

const LoansTableFilters: React.FC<LoansTableProps> = ({ onSearch, onClearFilter  }) => {
  const {
    consecutive, setConsecutive,
    approvedConsecutive, setApprovedConsecutive,
    invoiceConsecutive, setInvoiceConsecutive,
    createdAt, setCreatedAt,
    estimatedPaymentDate, setEstimatedPaymentDate,
    submitted, setSubmitted, clear,
    status, setStatus,
    validForm
  } = useLoansTableFilters();

  const submit = () => {
    setSubmitted(true);
    if (!validForm()) return;

    onSearch({
      consecutive: consecutive.getNumber() ?? undefined,
      approvedConsecutive: approvedConsecutive.getNumber() ?? undefined,
      invoiceConsecutive: invoiceConsecutive.getNumber() ?? undefined,
      createdAt: createdAt.getDate() ?? undefined,
      estimatedPaymentDate: estimatedPaymentDate.getDate() ?? undefined,
      statusCd: status ? Loan.enumToLoanStatusCd(status) : undefined
    });
  }

  const clearFilters = () => {
    setSubmitted(false);
    clear();
    onClearFilter();
  }

  return (
    <form className='flex flex-col space-y-5' onSubmit={(event) => { event.preventDefault(); submit(); }}>
      <SectionSelector
        value={status}
        onOptionSelected={(value) => { setStatus(value) }}
        options={[
          { label: 'Todos', value: null },
          { label: 'Abierto', value: LoanStatus.open },
          { label: 'Aprobado', value: LoanStatus.approved },
          { label: 'Depositado', value: LoanStatus.deposited },
          { label: 'Pagado', value: LoanStatus.paid },
          { label: 'Rechazado', value: LoanStatus.rejected }
        ]}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
        <Input
          className="text-sm p-2"
          placeholder="Consecutivo"
          type="number"
          value={consecutive.value}
          errorMessage={submitted ? consecutive.errorMessage() : null}
          onChange={(event) => { setConsecutive(Number.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          placeholder="Consecutivo aprobación"
          type="number"
          value={approvedConsecutive.value}
          errorMessage={submitted ? approvedConsecutive.errorMessage() : null}
          onChange={(event) => { setApprovedConsecutive(Number.dirtyAllowEmpty(event.target.value)) }}
          />
        <Input
          className="text-sm p-2"
          placeholder="Consecutivo factura"
          type="number"
          value={invoiceConsecutive.value}
          errorMessage={submitted ? invoiceConsecutive.errorMessage() : null}
          onChange={(event) => { setInvoiceConsecutive(Number.dirtyAllowEmpty(event.target.value)) }}
          />
        <Input
          className="text-sm p-2"
          type="date"
          placeholder="Fecha creación"
          value={createdAt.value}
          errorMessage={submitted ? createdAt.errorMessage() : null}
          onChange={(event) => { setCreatedAt(DateInput.dirtyAllowEmpty(event.target.value)) }}
        />
        <Input
          className="text-sm p-2"
          type="date"
          placeholder="Fecha pago"
          value={estimatedPaymentDate.value}
          errorMessage={submitted ? estimatedPaymentDate.errorMessage() : null}
          onChange={(event) => { setEstimatedPaymentDate(DateInput.dirtyAllowEmpty(event.target.value)) }}
          />
        <div className="flex flex-row space-x-3 items-center sm:items-end justify-center sm:justify-start mb-2">
          <Button className="text-sm py-1 px-4 h-fit" disabled={false} text="Buscar" type="submit" />
          <button className="text-sm font-normal text-sky-800 hover:text-sky-600 mb-0 sm:mb-1" onClick={() => { clearFilters(); }} type="submit">Limpiar filtros</button>
        </div>
      </div>
    </form>
  )
}

export default LoansTableFilters;