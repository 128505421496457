import { Config } from "../../../../domain/models";
import { ConfigEntity } from "../entities";

export default class ConfigMapper {
  static toModel(entity: ConfigEntity): Config {
    return new Config({
      minAmount: entity.min_amount,
      maxAmount: entity.max_amount,
      minDays: entity.min_days,
      maxDays: entity.max_days,
      defaultAnnualEffectiveInterestRate: entity.default_annual_effective_interest_rate,
      onlinePaymentFeeRate: entity.online_payment_fee_rate,
      dashboardUrl: entity.dashboard_url,
      dashboardPassword: entity.dashboard_password,
      contactEmail: entity.contact_email,
      contactPhone: entity.contact_phone,
      contactAddress: entity.contact_address,
      onlineSignatureFee: entity.online_signature_fee,
      creditScoreConsultationFee: entity.credit_score_consultation_fee
    })
  }
}