import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { FeeInfo, HistoryTrack, Loan, PaginatedModel, PaymentTransaction, ProjectedPayment } from "../../../domain/models";
import { HistoryTrackerMapper, LoanMapper, PaymentTransactionMapper, ProjectedPaymentMapper } from "./mappers";
import { LoansRepository } from "../../../domain/repositories";
import { HistoryTrackEntity, LoanEntity, PaginatedEntity, PaymentTransactionEntity, ProjectedPaymentEntity } from "./entities";
import { LocalStorageTokenRepository } from "../";
import { getLoansFilter, updateLoanPayload } from "../../../domain/repositories/LoansRepository";

export default class RestLoansRepository implements LoansRepository {
  private static instance: RestLoansRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestLoansRepository();
    }
    return this.instance;
  }

  async getLoans(page: number, filter?: getLoansFilter): Promise<[PaginatedModel<Loan>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const params = JsonUtil.removeBlankFields({
        page,
        consecutive: filter?.consecutive,
        approved_consecutive: filter?.approvedConsecutive,
        invoice_consecutive: filter?.invoiceConsecutive,
        created_at: filter?.createdAt,
        estimated_payment_date: filter?.estimatedPaymentDate,
        status_cd: filter?.statusCd,
        t: token
      });
      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get<PaginatedEntity<LoanEntity>>(`${Environment.backendUrl}/admin/v1/loans?${queryString}`);
      const loans = response.data.data.map((loanEntity) => LoanMapper.toModel(loanEntity));
      const paginatedModel = new PaginatedModel<Loan>({
        data: loans,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getById(id: string): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<LoanEntity>(`${Environment.backendUrl}/admin/v1/loans/${id}?t=${token}`);
      const loan = LoanMapper.toModel(response.data);
      return [loan, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateLoanFees(id: string, fees: FeeInfo[]): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.put<LoanEntity>(`${Environment.backendUrl}/admin/v1/loans/${id}/fees?t=${token}`, {
        fees: fees
      });
      const loan = LoanMapper.toModel(response.data);
      return [loan, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async resetFees(id: string): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.put<LoanEntity>(`${Environment.backendUrl}/admin/v1/loans/${id}/reset_fees?t=${token}`);
      const loan = LoanMapper.toModel(response.data);
      return [loan, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateLoan(id: string, info: updateLoanPayload): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.put<LoanEntity>(`${Environment.backendUrl}/admin/v1/loans/${id}?t=${token}`, JsonUtil.removeBlankFields(info));
      const loan = LoanMapper.toModel(response.data);
      return [loan, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getHistoryTracks(id: string, page: number): Promise<[PaginatedModel<HistoryTrack>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const params = JsonUtil.removeBlankFields({
        page,
        t: token
      });
      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get<PaginatedEntity<HistoryTrackEntity>>(`${Environment.backendUrl}/admin/v1/loans/${id}/history_tracks?${queryString}`);
      const tracks = response.data.data.map((trackEntity) => HistoryTrackerMapper.toModel(trackEntity));
      const paginatedModel = new PaginatedModel<HistoryTrack>({
        data: tracks,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async projectPayment(id: string, paidAt: Date): Promise<[ProjectedPayment?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.post<ProjectedPaymentEntity>(
        `${Environment.backendUrl}/admin/v1/loans/${id}/project_payment?t=${token}`,
        { paid_at: paidAt }
      );
      const payment = ProjectedPaymentMapper.toModel(response.data);
      return [payment, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getPaymentTransactions(id: string): Promise<[PaymentTransaction[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<PaymentTransactionEntity[]>(`${Environment.backendUrl}/admin/v1/loans/${id}/payment_transactions?t=${token}`);
      const transactions = response.data.map((transactionEntity) => PaymentTransactionMapper.toModel(transactionEntity));
      return [transactions, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getInvoice(id: string): Promise<[Blob?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<Blob>(`${Environment.backendUrl}/admin/v1/loans/${id}/invoice?t=${token}`, {
        responseType: 'blob'
      });
      return [response.data, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}