import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { User, PaginatedModel, BankAccount, Loan } from "../../../domain/models";
import { BankAccountMapper, LoanMapper, UserMapper } from "./mappers";
import { UsersRepository } from "../../../domain/repositories";
import { BankAccountEntity, LoanEntity, PaginatedEntity, UserEntity } from "./entities";
import { LocalStorageTokenRepository } from "..";
import { getUsersFilter, updateUserPayload } from "../../../domain/repositories/UsersRepository";

export default class RestUsersRepository implements UsersRepository {
  private static instance: RestUsersRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestUsersRepository();
    }
    return this.instance;
  }

  async getUsers(page: number, filter?: getUsersFilter): Promise<[PaginatedModel<User>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const params = JsonUtil.removeBlankFields({
        page,
        first_name: filter?.firstName,
        second_name: filter?.secondName,
        first_surname: filter?.firstSurname,
        second_surname: filter?.secondSurname,
        email: filter?.email,
        mobile_phone: filter?.mobilePhone,
        document_number: filter?.documentNumber,
        t: token
      });
      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get<PaginatedEntity<UserEntity>>(`${Environment.backendUrl}/admin/v1/users?${queryString}`);
      const users = response.data.data.map((userEntity) => UserMapper.toModel(userEntity));
      const paginatedModel = new PaginatedModel<User>({
        data: users,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getUser(userId: string): Promise<[User?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<UserEntity>(`${Environment.backendUrl}/admin/v1/users/${userId}?t=${token}`);
      const user = UserMapper.toModel(response.data);
      return [user, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateUser(userId: string, payload: updateUserPayload): Promise<[User?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.put<UserEntity>(`${Environment.backendUrl}/admin/v1/users/${userId}?t=${token}`, JsonUtil.removeBlankFields(payload));
      const user = UserMapper.toModel(response.data);
      return [user, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getBankAccount(userId: string): Promise<[BankAccount?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<BankAccountEntity>(`${Environment.backendUrl}/admin/v1/users/${userId}/bank_account?t=${token}`);
      let bankAccount = undefined;
      if(response.data) {
        bankAccount = BankAccountMapper.toModel(response.data);
      }
      return [bankAccount, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateBankAccount(userId: string, bankCode: string, typeCd: number, number: string): Promise<[BankAccount?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const payload = JsonUtil.removeBlankFields({
        bank_code: bankCode,
        type_cd: typeCd,
        number: number,
        t: token
      });

      const response = await axios.put<BankAccountEntity>(`${Environment.backendUrl}/admin/v1/users/${userId}/update_bank_account?t=${token}`, payload);
      const bankAccount = BankAccountMapper.toModel(response.data);

      return [bankAccount, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getUserLoans(userId: string, page: number): Promise<[PaginatedModel<Loan>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const params = JsonUtil.removeBlankFields({
        page,
        t: token
      });
      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get<PaginatedEntity<LoanEntity>>(`${Environment.backendUrl}/admin/v1/users/${userId}/loans?${queryString}`);
      const loans = response.data.data.map((loanEntity) => LoanMapper.toModel(loanEntity));
      const paginatedModel = new PaginatedModel<Loan>({
        data: loans,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateCreditCapacity(userId: string, amount: number): Promise<[User?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const payload = JsonUtil.removeBlankFields({
        amount: amount,
        t: token
      });
      const response = await axios.put<UserEntity>(`${Environment.backendUrl}/admin/v1/users/${userId}/update_credit_capacity?t=${token}`, payload);
      const user = UserMapper.toModel(response.data);

      return [user, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}