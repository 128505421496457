import { useState } from 'react';
import { EventEmailsRepository, LoanStatusChangeEmailsRepository } from '../../domain/repositories';
import { Alert, Constants  } from '../../utils';
import { RestEventEmailsRepository, RestLoanStatusChangeEmailsRepository } from '../../infrastructure/repositories';
import { Text } from '../../infrastructure/inputs';

const usePreviewEmail = (emailType: string) => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(Text.dirty(''));
  const [body, setBody] = useState(Text.dirty(''));

  const chooseCorrectRepository = (): (
    EventEmailsRepository | LoanStatusChangeEmailsRepository | undefined
  ) => {
    switch (emailType) {
      case Constants.EMAIL_TYPES['event']:
        return RestEventEmailsRepository.getInstance();
      case Constants.EMAIL_TYPES['loan_status_change']:
        return RestLoanStatusChangeEmailsRepository.getInstance();
      default:
        Alert.showError('Tipo de email inválido');
    }
  }

  const previewEmail = async (id: string) => {
    setLoading(true);
    const repository = chooseCorrectRepository();
    const [preview, errorMessage] = await repository!.previewEmail(id);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setSubject(Text.dirty(preview!.subject));
      setBody(Text.dirty(preview!.body));
    }
  }

  return {
    loading,
    subject,
    body,
    previewEmail
  };
};

export default usePreviewEmail;