import { ModelInfo } from "../../../../domain/models";
import { ModelInfoEntity } from "../entities";

export default class ModelInfoMapper {
  static toModel(entity: ModelInfoEntity): ModelInfo {
    return new ModelInfo({
      fraud: {
        fraudulent: entity.fraud.fraudulent,
        reason: entity.fraud.reason
      },
      risk: {
        phoneRisky: entity.risk.phone_risky,
        phoneReason: entity.risk.phone_reason,
        emailRisky: entity.risk.email_risky,
        emailReason: entity.risk.email_reason
      },
      paymentPrediction: entity.payment_prediction,
      userPreviousPaidLoansCount: entity.user_previous_paid_loans_count,
      userInArrears: entity.user_in_arrears,
      userPreviousPaidLegacyLoansCount: entity.user_previous_paid_legacy_loans_count,
      userInArrearsInLegacyApp: entity.user_in_arrears_in_legacy_app
    });
  }
}