import { useState } from 'react';
import { BanksRepository } from '../../domain/repositories';
import { Alert } from '../../utils';
import { RestBanksRepository } from '../../infrastructure/repositories';
import { InputValidator, Text } from '../../infrastructure/inputs';
import { useNavigate } from 'react-router-dom';
import Routes from '../../config/Routes';

const useCreateBank = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(Text.dirty(''));
  const [code, setCode] = useState(Text.dirty(''));
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const banksRepository: BanksRepository = RestBanksRepository.getInstance();

  const createBank = async () => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [, errorMessage] = await banksRepository.createBank(name.value, code.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      navigate(Routes.BANKS_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([name, code]);
  }

  return {
    loading, createBank,
    name, setName,
    code, setCode,
    submitted
  };
};

export default useCreateBank;