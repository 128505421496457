import { useEffect } from "react";
import { InputValidator, Text } from "../../infrastructure/inputs";
import { Button, HTMLInput, PageContainer, Splash } from "../components"
import { usePreviewTemplate, useUpdateLoanDocumentTemplate } from "../hooks";
import { useParams } from "react-router-dom";

const UpdateLoanDocumentTemplatePage = () => {
  const { id } = useParams();
  const {
    loading, template, setTemplate,
    submitted, updateLoanDocumentTemplate, loadLoanDocumentTemplate,
    type
  } = useUpdateLoanDocumentTemplate();
  const {
    loading:loadingPreview, previewTemplate
  } = usePreviewTemplate();

  useEffect(() => {
    loadLoanDocumentTemplate(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex flex-col space-y-6">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Documentos</h1>
          <h2 className="text-base font-semibold">{type}</h2>
        </div>
      </div>

      <form className="flex flex-col space-y-4" onSubmit={(event) => { event.preventDefault(); updateLoanDocumentTemplate(id!) }}>
        <HTMLInput
          placeholder="Contenido"
          value={template.value}
          showCode={true}
          errorMessage={submitted ? template.errorMessage() : null}
          onChange={(event) => { setTemplate(Text.dirty(event.target.value)); }}
        />

        <div className="flex flex-row w-full items-center justify-center space-x-10">
          <Button
            className="text-lg py-2 px-4 w-fit"
            loading={loadingPreview}
            disabled={InputValidator.anyEmpty([template])}
            text="Ver preview"
            type="button"
            onClick={() => { previewTemplate(id!) }}
          />

          <Button
            className="text-lg py-2 px-4 w-fit"
            loading={loading}
            disabled={InputValidator.anyEmpty([template])}
            text="Guardar"
            type="submit"
          />
        </div>
      </form>
    </PageContainer>
  )
}

export default UpdateLoanDocumentTemplatePage;