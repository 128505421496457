interface IProviderContract {
  url?: string;
  signedContractUrl?: string;
}

export default class ProviderContract {
  url?: string;
  signedContractUrl?: string;

  constructor({
    url, signedContractUrl
  }: IProviderContract) {
    this.url = url;
    this.signedContractUrl = signedContractUrl;
  }
}