import { useEffect } from "react";
import { InputValidator, Text } from "../../infrastructure/inputs";
import { Button, HTMLInput, Input, PageContainer, Splash } from "../components"
import { useUpdateEventEmail } from "../hooks";
import { NavLink, useParams } from "react-router-dom";
import Routes from "../../config/Routes";
import { Constants } from "../../utils";

const UpdateEventEmailPage = () => {
  const { id } = useParams();
  const {
    loading, subject, body,
    setSubject, setBody, submitted,
    updateEventEmail, loadEventEmail,
    eventType
  } = useUpdateEventEmail();

  useEffect(() => {
    loadEventEmail(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex flex-col space-y-6">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Emails para avisar un evento</h1>
          <h2 className="text-base font-semibold">{eventType}</h2>
        </div>
      </div>

      <form className="flex flex-col space-y-4" onSubmit={(event) => { event.preventDefault(); updateEventEmail(id!) }}>
        <Input
          placeholder="Asunto"
          className="p-3"
          value={subject.value}
          errorMessage={submitted ? subject.errorMessage() : null}
          onChange={(event) => { setSubject(Text.dirty(event.target.value)); }}
        />

        <HTMLInput
          placeholder="Contenido"
          value={body.value}
          showCode={true}
          errorMessage={submitted ? body.errorMessage() : null}
          onChange={(event) => { setBody(Text.dirty(event.target.value)); }}
        />

        <div className="flex flex-row w-full items-center justify-center space-x-10">
          <NavLink
            target="_blank"
            to={Routes.PREVIEW_EMAIL_PATH.replace(':id', id!).replace(':type', Constants.EMAIL_TYPES['event'])}
            className=" text-sky-600 hover:text-sky-800"
          >
            Ver preview
          </NavLink>

          <Button
            className="text-lg py-2 px-4 w-fit"
            loading={loading}
            disabled={InputValidator.anyEmpty([subject, body])}
            text="Guardar"
            type="submit"
          />
        </div>
      </form>
    </PageContainer>
  )
}

export default UpdateEventEmailPage;