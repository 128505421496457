interface CheckboxProps {
  onChange?: (value: boolean) => void;
  className?: string;
  value: boolean;
  children?: React.ReactNode;
  errorMessage?: string | null;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ className, value, onChange, children, errorMessage, disabled = false }) => {
  return (
    <div className="w-full flex flex-col flex-wrap space-y-2 justify-end">
      <button disabled={disabled} className="flex flex-row space-x-2 items-center" onClick={() => { if (onChange) { onChange!(!value) }; }} type='button'>
        <input
          disabled={disabled}
          className={`${className}`}
          type={'checkbox'}
          checked={value}
          onChange={() => {}}
        />
        
        {children}
      </button>
      {errorMessage && <div className="pr-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default Checkbox;