import { CreditCapacity } from "../../../../domain/models";
import { CreditCapacityEntity } from "../entities";

export default class CreditCapacityMapper {
  static toModel(entity: CreditCapacityEntity): CreditCapacity {
    return new CreditCapacity({
      amount: entity.amount,
      used: entity.used,
    })
  }
}