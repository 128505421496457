import { PageContainer } from "../components"
import { useEffect } from "react";
import { usePurposes } from "../hooks";

const PurposesPage = () => {
  const { loadPurposes, purposes } = usePurposes();

  useEffect(() => {
    loadPurposes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="flex-col">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Propósitos</h1>
          <h2 className="text-base">Listado de propósitos</h2>
        </div>
      </div>

      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-10 px-10 md:px-0">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-scroll border border-gray-200 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">ID</th>
                  <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Nombre</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  purposes.map((purpose, index) => (
                    <tr key={index}>
                      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{purpose.id}</td>
                      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{purpose.name}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default PurposesPage;