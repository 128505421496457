export default class Routes {
  static HOME_PATH = '/';
  static LOGIN_PATH = '/login';
  static LOANS_PATH = '/loans';
  static LOAN_DETAIL_PATH = '/loans/:id';
  static USERS_PATH = '/users';
  static USER_DETAIL_PATH = '/users/:id'
  static PURPOSES_PATH = '/purposes';
  static BANKS_PATH = '/banks';
  static NEW_BANK_PATH = '/banks/new';
  static EVENT_EMAILS_PATH = '/event_emails';
  static UPDATE_EVENT_EMAIL_PATH = '/event_emails/:id/update'
  static STATUS_EMAILS_PATH = '/status_emails';
  static UPDATE_LOAN_STATUS_CHANGE_EMAIL_PATH = '/status_emails/:id/update'
  static UPDATE_LOAN_DOCUMENT_TEMPLATE_PATH = '/loan_document_templates/:id/update'
  static DOCUMENTS_PATH = '/loan_document_templates';
  static CONFIG_PATH = '/config';
  static PREVIEW_EMAIL_PATH = '/emails/:type/preview/:id';
}