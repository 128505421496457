import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { Purpose } from "../../../domain/models";
import { PurposeMapper } from "./mappers";
import { PurposesRepository } from "../../../domain/repositories";
import { PurposeEntity } from "./entities";
import { LocalStorageTokenRepository } from "../";

export default class RestPurposesRepository implements PurposesRepository {
  private static instance: RestPurposesRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestPurposesRepository();
    }
    return this.instance;
  }

  async getPurposes(): Promise<[Purpose[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<PurposeEntity[]>(`${Environment.backendUrl}/admin/v1/purposes?t=${token}`);
      const purposes = response.data.map((purposeEntity) => PurposeMapper.toModel(purposeEntity));

      return [purposes, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}