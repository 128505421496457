import { useState } from 'react';
import { EventEmailsRepository } from '../../domain/repositories';
import { EventEmail } from '../../domain/models';
import { Alert } from '../../utils';
import { RestEventEmailsRepository } from '../../infrastructure/repositories';

const useEventEmails = () => {
  const [loading, setLoading] = useState(false);
  const [eventEmails, setEventEmails] = useState<EventEmail[]>([]);

  const loansRepository: EventEmailsRepository = RestEventEmailsRepository.getInstance();

  const loadEventEmails = async () => {
    setLoading(true);
    const [eventEmails, errorMessage] = await loansRepository.getEmails();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setEventEmails(eventEmails!);
    }
  };

  return {
    loading,
    loadEventEmails,
    eventEmails,
  };
};

export default useEventEmails;