import { useUpdateUserCreditCapacity } from "../../hooks";
import { Button, Splash, MoneyInput } from "../";
import { MoneyValue } from "../../../infrastructure/inputs";
import { User } from "../../../domain/models";

interface UserCreditCapacityTableProps {
  children: React.ReactNode;
}

const UserCreditCapacityTable: React.FC<UserCreditCapacityTableProps> = ({ children }) => {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

interface UserCreditCapacityTableEntryProps {
  title: string;
  children: React.ReactNode;
  dark?: boolean;
}

const UserCreditCapacityTableEntry: React.FC<UserCreditCapacityTableEntryProps> = ({ title, children, dark = false }) => {
  return (
    <tr className={dark ? 'bg-gray-100' : ''}>
      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">{title}</th>
      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
        {children}
      </td>
    </tr>
  )
}

interface UserCreditCapacityProps {
  user: User;
  setUser: (user: User) => void;
}

const UserCreditCapacity: React.FC<UserCreditCapacityProps> = ({ user, setUser }) => {
  const {
    amount, setAmount,
    submitted, updateCreditCapacity,
    loading
  } = useUpdateUserCreditCapacity(user);

  if (loading) {
    return (
      <Splash />
    )
  }

  return (
    <form className="flex flex-col space-y-5 bg-white rounded-lg p-10" onSubmit={(event) => { event.preventDefault(); updateCreditCapacity(setUser); }}>
      <h2 className="text-xl font-bold">Monto preaprobado</h2>
      <UserCreditCapacityTable>
        <UserCreditCapacityTableEntry title="Cantidad">
          <MoneyInput
            className="p-3"
            value={amount.getMoney() ?? '$'}
            errorMessage={submitted ? amount.errorMessage() : null}
            onChange={(event) => {
              setAmount(MoneyValue.dirty(event.target.value))
            }}
          />
        </UserCreditCapacityTableEntry>
      </UserCreditCapacityTable>

      <div className="flex w-full items-center justify-center">
        <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Guardar" type="submit" />
      </div>
    </form>
  )
}

export default UserCreditCapacity;