interface IPreviewedEmail {
  subject: string;
  body: string;
}

export default class PreviewedEmail {
  subject: string;
  body: string;
  
  constructor({
    subject, body
  }: IPreviewedEmail) {
    this.subject = subject;
    this.body = body;
  }
}