import { InputValidator, Text } from "../../infrastructure/inputs";
import { Button, Input, PageContainer } from "../components"
import { useCreateBank } from "../hooks";

const NewBankPage = () => {
  const {
    loading, name, code,
    setName, setCode, submitted,
    createBank
  } = useCreateBank();

  return (
    <PageContainer className="flex flex-col space-y-10">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Bancos</h1>
          <h2 className="text-base">Crear nuevo banco</h2>
        </div>
      </div>

      <form className="flex flex-col space-y-8" onSubmit={(event) => { event.preventDefault(); createBank(); }}>
        <Input
          placeholder="Nombre"
          className="p-3"
          value={name.value}
          errorMessage={submitted ? name.errorMessage() : null}
          onChange={(event) => { setName(Text.dirty(event.target.value)); }}
        />

        <Input
          placeholder="Código"
          className="p-3"
          value={code.value}
          errorMessage={submitted ? code.errorMessage() : null}
          onChange={(event) => { setCode(Text.dirty(event.target.value)); }}
        />

        <div className="flex w-full items-center justify-center space-x-2">
          <Button
            className="text-lg py-2 px-4 w-fit"
            loading={loading}
            disabled={InputValidator.anyEmpty([name, code])}
            text="Guardar"
            type="submit"
          />
        </div>
      </form>
    </PageContainer>
  )
}

export default NewBankPage;