import { LoanDocumentTemplate, } from "../../../../domain/models";
import { LoanDocumentTemplateType } from "../../../../domain/models/loan_document_template";
import { LoanDocumentTemplateEntity } from "../entities";

export default class LoanDocumentTemplateMapper {
  static toModel(entity: LoanDocumentTemplateEntity): LoanDocumentTemplate {
    return new LoanDocumentTemplate({
      id: entity.id,
      createdAt: entity.created_at,
      template: entity.template,
      type: LoanDocumentTemplateMapper.typeCdToType(entity.type_cd)
    })
  }

  static typeCdToType = (typeCd: number): LoanDocumentTemplateType => {
    switch (typeCd) {
      case 0:
        return LoanDocumentTemplateType.invoice;
      case 1:
        return LoanDocumentTemplateType.contract;
      default:
        throw new Error(`Unknown type: ${typeCd}`);
    }
  }
}