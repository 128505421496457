import AbstractInput from './AbstractInput';

enum DecimalError { empty, format }

export default class Number extends AbstractInput<string, DecimalError> {
  REGEX = /^\d+([.,]\d+)?$/;


  empty = () => {
    return this.value === null || this.value === undefined || this.value.trim() === '';
  };

  errorMessage = () => {
    const displayError = this.validator();

    if (displayError === DecimalError.empty) return 'El campo es requerido';
    if (displayError === DecimalError.format) return 'No es un decimal';
    return null;
  };

  validator = () => {
    if(!this.allowEmpty) {
      if (this.empty()) return DecimalError.empty;
    }
    if (this.empty()) return null;

    if (!this.REGEX.test(this.value)) return DecimalError.format;

    return null;
  };

  getNumber = (): number | null => {
    const number = parseFloat(this.value.replace(/,/g, "."));
    if (isNaN(number) || number === undefined) {
      return null;
    }

    return number;
  }
}