import { useState } from 'react';
import { LoanStatusChangeEmailsRepository } from '../../domain/repositories';
import { Alert, Constants } from '../../utils';
import { RestLoanStatusChangeEmailsRepository } from '../../infrastructure/repositories';
import { InputValidator, Text } from '../../infrastructure/inputs';
import { LoanStatusChangeEmailType } from '../../domain/models/loan_status_change_email';

const useUpdateLoanStatusChangeEmail = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<LoanStatusChangeEmailType | undefined>(undefined);
  const [subject, setSubject] = useState(Text.dirty(''));
  const [body, setBody] = useState(Text.dirty(''));
  const [submitted, setSubmitted] = useState(false);

  const loanStatusChangeRepository: LoanStatusChangeEmailsRepository = RestLoanStatusChangeEmailsRepository.getInstance();

  const loadLoanStatusChangeEmail = async (id: string) => {
    setLoading(true);
    const [loanStatusChangeEmail, errorMessage] = await loanStatusChangeRepository.getEmail(id);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setSubject(Text.dirty(loanStatusChangeEmail!.subject));
      setBody(Text.dirty(loanStatusChangeEmail!.body));
      setStatus(loanStatusChangeEmail?.statusType);
    }
  }

  const updateLoanStatusChangeEmail = async (id: string) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [loanStatusChangeEmail, errorMessage] = await loanStatusChangeRepository.updateEmail(id, subject.value, body.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      Alert.showSuccess(Constants.UPDATED_EMAIL_CORRECTLY_MSG);
      setSubject(Text.dirty(loanStatusChangeEmail!.subject));
      setBody(Text.dirty(loanStatusChangeEmail!.body));
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([subject, subject]);
  }

  return {
    loading,
    subject, setSubject,
    body, setBody,
    submitted, updateLoanStatusChangeEmail,
    loadLoanStatusChangeEmail, status
  };
};

export default useUpdateLoanStatusChangeEmail;