import { Purpose } from "../../../../domain/models";
import { PurposeEntity } from "../entities";

export default class PurposeMapper {
  static toModel(entity: PurposeEntity): Purpose {
    return new Purpose({
      id: entity.id,
      name: entity.name
    });
  }
}