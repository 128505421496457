import { useState } from 'react';
import { UsersRepository } from '../../domain/repositories';
import { User } from '../../domain/models';
import { Alert, Constants, DateUtil } from '../../utils';
import { Date, Email, HomePhone, InputValidator, MobilePhone, Selector, Text } from '../../infrastructure/inputs';
import { updateUserPayload } from '../../domain/repositories/UsersRepository';
import { RestUsersRepository } from '../../infrastructure/repositories';

const useUpdateUser = (user: User) => {
  const [firstName, setFirstName] = useState(Text.dirty(user?.firstName ?? ''));
  const [secondName, setSecondName] = useState(Text.dirtyAllowEmpty(user?.secondName ?? ''));
  const [firstSurname, setFirstSurname] = useState(Text.dirty(user?.firstSurname ?? ''));
  const [secondSurname, setSecondSurname] = useState(Text.dirty(user?.secondSurname ?? ''));
  const [email, setEmail] = useState(Email.dirty(user?.email ?? ''));
  const [birthDate, setBirthDate] = useState(Date.dirty(user?.birthDate ? DateUtil.shortDate(user?.birthDate) : ''));
  const [mobilePhone, setMobilePhone] = useState(MobilePhone.dirty(user?.mobilePhone ?? ''));
  const [phone, setPhone] = useState(HomePhone.dirtyAllowEmpty(user?.phone ?? ''));
  const [gender, setGender] = useState(Selector.dirtyWithOptions(user?.genderCd?.toString() ?? '', Object.values(Constants.GENDER_TYPES)));
  const [maritalStatus, setMaritalStatus] = useState(Selector.dirtyWithOptions(user?.maritalStatusCd?.toString() ?? '', Object.values(Constants.CIVIL_STATUS_TYPES)));
  const [documentNumber, setDocumentNumber] = useState(Text.dirty(user?.documentNumber ?? ''));
  const [documentExpeditionDate, setDocumentExpeditionDate] = useState(Date.dirty(user?.documentExpeditionDate ? DateUtil.shortDate(user?.documentExpeditionDate) : ''));
  const [documentType, setDocumentType] = useState(Selector.dirtyWithOptions(user?.documentTypeCd?.toString() ?? '', Object.values(Constants.DOCUMENT_TYPES)));
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const updateUser = async (setUser: (user: User) => void) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [newUser, errorMessage] = await usersRepository.updateUser(user.id, payload());
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setUser(newUser!);
    }
  }

  const payload = (): updateUserPayload => {
    return {
      first_name: firstName.value,
      second_name: secondName.value,
      first_surname: firstSurname.value,
      second_surname: secondSurname.value,
      email: email.value,
      birth_date: birthDate.getDate()!,
      mobile_phone: mobilePhone.value,
      phone: phone.value,
      gender_cd: gender.getNumber()!,
      marital_status_cd: maritalStatus.getNumber()!,
      document_number: documentNumber.value,
      document_expedition_date: documentExpeditionDate.getDate()!,
      document_type_cd: documentType.getNumber()!,
    };
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      firstName,
      secondName,
      firstSurname,
      secondSurname,
      email,
      birthDate,
      mobilePhone,
      phone,
      gender,
      maritalStatus,
      documentNumber,
      documentExpeditionDate,
      documentType,
    ]);
  }

  return {
    firstName, setFirstName,
    secondName, setSecondName,
    firstSurname, setFirstSurname,
    secondSurname, setSecondSurname,
    email, setEmail,
    birthDate, setBirthDate,
    mobilePhone, setMobilePhone,
    phone, setPhone,
    gender, setGender,
    maritalStatus, setMaritalStatus,
    documentNumber, setDocumentNumber,
    documentExpeditionDate, setDocumentExpeditionDate,
    documentType, setDocumentType,
    submitted, updateUser,
    loading
  };
};

export default useUpdateUser;