import { ModelInfo } from "../../../domain/models";
import { Constants } from "../../../utils";

interface LoanModelResultProps {
  modelInfo: ModelInfo;
}

const LoanModelResult: React.FC<LoanModelResultProps> = ({ modelInfo }) => {
  const highResultLabel = (
    <div className="rounded-lg bg-red-200 w-fit px-4">
      RIESGO ALTO
    </div>
  );
  const lowResultLabel = (
    <div className="rounded-lg bg-green-200 w-fit px-4">
      RIESGO BAJO
    </div>
  );
  const undefinedResultLabel = (
    <div className="rounded-lg bg-gray-200 w-fit px-4">
      INCONCLUSO
    </div>
  );

  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-scroll border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Cuantos créditos nos ha pagado antes?</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap space-y-2">
                  <div className="w-fit px-4">
                    {modelInfo.userPreviousPaidLoansCount} en Prestaseguro
                  </div>
                  <div className="border-t border-gray-300 hidden md:block"></div>
                  <div className="w-fit px-4">
                    {modelInfo.userPreviousPaidLegacyLoansCount} en Prestándonos
                  </div>
                </td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Tiene otros créditos en mora?</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap space-y-2">
                  <div className="w-fit px-4">
                    {modelInfo.userInArrears ? 'Si' : 'No'}, en Prestaseguro
                  </div>
                  <div className="border-t border-gray-300 hidden md:block"></div>
                  <div className="w-fit px-4">
                    {modelInfo.userInArrearsInLegacyApp ? 'Si' : 'No'}, en Prestándonos
                  </div>
                </td>
              </tr>
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun email</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  { modelInfo.risk.emailRisky ? highResultLabel : lowResultLabel }
                </td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun celular</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {modelInfo.risk.phoneRisky ? highResultLabel : lowResultLabel}
                </td>
              </tr>
              <tr className="bg-gray-100">
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo fraude</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap flex-col flex space-y-2">
                  {modelInfo.fraud.fraudulent ? highResultLabel : lowResultLabel}
                  {
                    modelInfo.fraud.reason ? (
                      <div>Reason: {modelInfo.fraud.reason}</div>
                    ) : null
                  }
                </td>
              </tr>
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Riesgo segun modelo predictivo</th>
                <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {modelInfo.paymentPrediction === Constants.MODEL_PREDICTION_RESULTS['user_pays'] ? lowResultLabel : null}
                  {modelInfo.paymentPrediction === Constants.MODEL_PREDICTION_RESULTS['user_does_not_pay'] ? highResultLabel : null}
                  {modelInfo.paymentPrediction === Constants.MODEL_PREDICTION_RESULTS['error'] ? undefinedResultLabel : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LoanModelResult;