import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { LoanDocumentTemplate } from "../../../domain/models";
import { LoanDocumentTemplateMapper } from "./mappers";
import { LoanDocumentTemplatesRepository } from "../../../domain/repositories";
import { LoanDocumentTemplateEntity } from "./entities";
import { LocalStorageTokenRepository } from "..";

export default class RestLoanDocumentTemplatesRepository implements LoanDocumentTemplatesRepository {
  private static instance: RestLoanDocumentTemplatesRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestLoanDocumentTemplatesRepository();
    }
    return this.instance;
  }

  async getEmails(): Promise<[LoanDocumentTemplate[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<LoanDocumentTemplateEntity[]>(`${Environment.backendUrl}/admin/v1/loan_document_templates?t=${token}`);
      const emails = response.data.map(LoanDocumentTemplateMapper.toModel);

      return [emails, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getEmail(id: string): Promise<[LoanDocumentTemplate?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<LoanDocumentTemplateEntity>(`${Environment.backendUrl}/admin/v1/loan_document_templates/${id}?t=${token}`);
      const email = LoanDocumentTemplateMapper.toModel(response.data);

      return [email, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateTemplate(id: string, template: string): Promise<[LoanDocumentTemplate?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.put<LoanDocumentTemplateEntity>(`${Environment.backendUrl}/admin/v1/loan_document_templates/${id}?t=${token}`, {
        template,
      });
      const email = LoanDocumentTemplateMapper.toModel(response.data);

      return [email, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async previewTemplate(id: string): Promise<[Blob?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<Blob>(`${Environment.backendUrl}/admin/v1/loan_document_templates/${id}/preview?t=${token}`, {
        responseType: 'blob'
      });
      return [response.data, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}