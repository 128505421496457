import { useEffect } from "react";
import { Decimal, InputValidator, MoneyValue, Number, Text } from "../../infrastructure/inputs";
import { Button, Input, MoneyInput, PageContainer, Splash } from "../components"
import { useConfig } from "../hooks";

const ConfigPage = () => {
  const {
    loading, loadConfig,
    minAmount, setMinAmount,
    maxAmount, setMaxAmount,
    minDays, setMinDays,
    maxDays, setMaxDays,
    interestRate, setInterestRate,
    onlineFeeRate, setOnlineFeeRate,
    submitted, updateConfig,
    config, contactEmail, setContactEmail,
    contactPhone, setContactPhone,
    contactAddress, setContactAddress,
    onlineSignatureFee, setOnlineSignatureFee,
    creditScoreConsultationFee, setCreditScoreConsultationFee
  } = useConfig();

  useEffect(() => {
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(loading) {
    return (
      <Splash />
    )
  }

  return (
    <PageContainer className="flex flex-col space-y-10 px-10">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Configuración</h1>
          <h2 className="text-base">Actualizar configuración</h2>
        </div>
      </div>

      <form className="flex flex-col space-y-8" onSubmit={(event) => { event.preventDefault(); updateConfig(); }}>
        <div className="flex flex-col space-y-2">
          <label className="font-bold text-sm opacity-65">URL y contraseña de dashboard</label>
          <Input
            className="p-3"
            value={config?.dashboardUrl ?? 'N/A'}
            disabled
          />

          <Input
            className="p-3"
            value={config?.dashboardPassword ?? 'N/A'}
            disabled
          />
        </div>

        <div className="flex flex-col md:flex-row space-x-0 md:space-x-10 space-y-4 md:space-y-0">
          <div className="w-full md:w-1/2 flex flex-col space-y-4">
            <MoneyInput
              placeholder="Mínimo monto a prestar"
              className="p-3"
              value={minAmount.getMoney() ?? '$'}
              errorMessage={submitted ? minAmount.errorMessage() : null}
              onChange={(event) => { setMinAmount(MoneyValue.dirty(event.target.value)); }}
            />

            <MoneyInput
              placeholder="Máximo monto a prestar"
              className="p-3"
              value={maxAmount.getMoney() ?? '$'}
              errorMessage={submitted ? maxAmount.errorMessage() : null}
              onChange={(event) => { setMaxAmount(MoneyValue.dirty(event.target.value)); }}
            />

            <Input
              type="number"
              placeholder="Mínimo plazo de dias de pago"
              className="p-3"
              value={minDays.value}
              errorMessage={submitted ? minDays.errorMessage() : null}
              onChange={(event) => { setMinDays(Number.dirty(event.target.value)); }}
            />

            <Input
              type="number"
              placeholder="Máximo plazo de dias de pago"
              className="p-3"
              value={maxDays.value}
              errorMessage={submitted ? maxDays.errorMessage() : null}
              onChange={(event) => { setMaxDays(Number.dirty(event.target.value)); }}
            />

            <Input
              step={0.1}
              type="number"
              placeholder="Tasa de interés corriente E.A (Efectivo Anual) %"
              className="p-3"
              value={interestRate.value}
              errorMessage={submitted ? interestRate.errorMessage() : null}
              onChange={(event) => { setInterestRate(Decimal.dirty(event.target.value)); }}
            />

            <Input
              step={0.1}
              type="number"
              placeholder="Recargo por pago en línea %"
              className="p-3"
              value={onlineFeeRate.value}
              errorMessage={submitted ? onlineFeeRate.errorMessage() : null}
              onChange={(event) => { setOnlineFeeRate(Decimal.dirty(event.target.value)); }}
            />
          </div>

          <div className="w-full md:w-1/2 space-y-4">
            <MoneyInput
              placeholder="Cobro por firma en línea"
              className="p-3"
              value={onlineSignatureFee.getMoney() ?? '$'}
              errorMessage={submitted ? onlineSignatureFee.errorMessage() : null}
              onChange={(event) => { setOnlineSignatureFee(MoneyValue.dirty(event.target.value)); }}
            />

            <MoneyInput
              placeholder="Cobro por consulta de score de crédito"
              className="p-3"
              value={creditScoreConsultationFee.getMoney() ?? '$'}
              errorMessage={submitted ? creditScoreConsultationFee.errorMessage() : null}
              onChange={(event) => { setCreditScoreConsultationFee(MoneyValue.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Email de contacto"
              className="p-3"
              value={contactEmail.value}
              errorMessage={submitted ? contactEmail.errorMessage() : null}
              onChange={(event) => { setContactEmail(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Celular de contacto"
              className="p-3"
              value={contactPhone.value}
              errorMessage={submitted ? contactPhone.errorMessage() : null}
              onChange={(event) => { setContactPhone(Text.dirty(event.target.value)); }}
            />

            <Input
              placeholder="Dirección de contacto"
              className="p-3"
              value={contactAddress.value}
              errorMessage={submitted ? contactAddress.errorMessage() : null}
              onChange={(event) => { setContactAddress(Text.dirty(event.target.value)); }}
            />
          </div>
        </div>

        <div className="flex w-full items-center justify-center space-x-2">
          <Button
            className="text-lg py-2 px-4 w-fit"
            loading={loading}
            disabled={InputValidator.anyEmpty([minAmount, maxAmount, minDays, maxDays, interestRate, onlineFeeRate, contactEmail, contactPhone, contactAddress, onlineSignatureFee, creditScoreConsultationFee])}
            text="Guardar"
            type="submit"
          />
        </div>
      </form>
    </PageContainer>
  )
}

export default ConfigPage;