import { useEffect } from "react";
import { useLoans } from "../../hooks";

interface LoanTransactionsProps {
  loanId: string;
}

const LoanTransactions: React.FC<LoanTransactionsProps> = ({ loanId }) => {
  const { loadPaymentTransactions, paymentTransactions } = useLoans();

  useEffect(() => {
    loadPaymentTransactions(loanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(paymentTransactions.length === 0) {
    return (
      <div className="flex flex-wrap flex-col justify-center items-center mt-5 md:mt-20">
        <h2 className="font-bold text-md opacity-50 text-center">Por el momento no tiene pagos hechos en línea</h2>
      </div>
    )
  }

  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-scroll border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">ID</th>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Fecha</th>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Tipo</th>
                <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Fue exitoso?</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                paymentTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction.id}</td>
                    <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction.createdAt.toLocaleString()}</td>
                    <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction._type}</td>
                    <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction.succeeded ? 'Sí' : 'No'}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LoanTransactions;