import { useBanks, useUpdateUserBankAccount, useUsers } from "../../hooks";
import { Input, OptionInput, Button, Splash } from "../";
import { Number, Selector } from "../../../infrastructure/inputs";
import { Constants } from "../../../utils";
import { Bank, BankAccount, User } from "../../../domain/models";
import { useEffect } from "react";

interface UserBankAccountTableProps {
  children: React.ReactNode;
}

const UserBankAccountTable: React.FC<UserBankAccountTableProps> = ({ children }) => {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-scroll border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

interface UserBankAccountTableEntryProps {
  title: string;
  children: React.ReactNode;
  dark?: boolean;
}

const UserBankAccountTableEntry: React.FC<UserBankAccountTableEntryProps> = ({ title, children, dark = false }) => {
  return (
    <tr className={dark ? 'bg-gray-100' : ''}>
      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">{title}</th>
      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
        {children}
      </td>
    </tr>
  )
}

interface UserBankAccountFormProps {
  user: User;
  banks: Bank[];
  bankAccount?: BankAccount;
  setBankAccount: (bankAccount: BankAccount) => void;
}

const UserBankAccountForm: React.FC<UserBankAccountFormProps> = ({ user, banks, bankAccount, setBankAccount }) => {
  const {
    bankAccountType, setBankAccountType,
    bankCode, setBankCode,
    bankAccountNumber, setBankAccountNumber,
    submitted, updateBankAccount,
    loading
  } = useUpdateUserBankAccount(user, banks, bankAccount);

  if (loading) {
    return (
      <Splash />
    )
  }

  return (
    <form className="flex flex-col space-y-5 bg-white rounded-lg p-10" onSubmit={(event) => { event.preventDefault(); updateBankAccount(setBankAccount); }}>
      <h2 className="text-xl font-bold">Cuenta bancaria</h2>
      <UserBankAccountTable>
        <UserBankAccountTableEntry title="Tipo de cuenta">
          <OptionInput
            options={Object.keys(Constants.BANK_ACCOUNT_TYPES).map((key) => ({ value: Constants.BANK_ACCOUNT_TYPES[key], label: key }))}
            selectedOption={bankAccountType.value}
            errorMessage={submitted ? bankAccountType.errorMessage() : null}
            onChange={(event) => { setBankAccountType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.BANK_ACCOUNT_TYPES))) }}
          />
        </UserBankAccountTableEntry>

        <UserBankAccountTableEntry title="Banco">
          <OptionInput
            options={banks.map(bank => ({ value: bank.code, label: bank.name }))}
            selectedOption={bankCode.value}
            errorMessage={submitted ? bankCode.errorMessage() : null}
            onChange={(event) => { setBankCode(Selector.dirtyWithOptions(event.target.value, banks.map(bank => bank.code) ?? [])) }}
          />
        </UserBankAccountTableEntry>

        <UserBankAccountTableEntry title="Número de cuenta">
          <Input
            className="p-3"
            type="number"
            value={bankAccountNumber.value}
            errorMessage={submitted ? bankAccountNumber.errorMessage() : null}
            onChange={(event) => { setBankAccountNumber(Number.dirty(event.target.value)) }}
          />
        </UserBankAccountTableEntry>
      </UserBankAccountTable>

      <div className="flex w-full items-center justify-center">
        <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Guardar" type="submit" />
      </div>
    </form>
  )
}

interface UserBankAccountProps {
  user: User;
}

const UserBankAccount: React.FC<UserBankAccountProps> = ({ user }) => {
  const { loading: loadingBanks, loadBanks, banks } = useBanks();
  const {
    loading:loadingBankAccount,
    loadUserBankAccount,
    setBankAccount,
    bankAccount
  } = useUsers();

  useEffect(() => {
    loadBanks();
    loadUserBankAccount(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingBanks || loadingBankAccount || !banks) {
    return (
      <Splash />
    )
  }

  return (
    <UserBankAccountForm
      user={user}
      banks={banks}
      bankAccount={bankAccount}
      setBankAccount={setBankAccount}
    />
  )
}

export default UserBankAccount;