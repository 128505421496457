import { useState } from 'react';
import { LoanDocumentTemplatesRepository } from '../../domain/repositories';
import { LoanDocumentTemplate } from '../../domain/models';
import { Alert } from '../../utils';
import { RestLoanDocumentTemplatesRepository } from '../../infrastructure/repositories';

const useLoanDocumentTemplates = () => {
  const [loading, setLoading] = useState(false);
  const [loanDocumentTemplates, setLoanDocumentTemplates] = useState<LoanDocumentTemplate[]>([]);

  const loanStatusChangeRepository: LoanDocumentTemplatesRepository = RestLoanDocumentTemplatesRepository.getInstance();

  const loadLoanDocumentTemplates = async () => {
    setLoading(true);
    const [loanDocumentTemplates, errorMessage] = await loanStatusChangeRepository.getEmails();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setLoanDocumentTemplates(loanDocumentTemplates!);
    }
  };

  return {
    loading,
    loadLoanDocumentTemplates,
    loanDocumentTemplates,
  };
};

export default useLoanDocumentTemplates;