import { useState } from 'react';
import { LoanDocumentTemplatesRepository } from '../../domain/repositories';
import { Alert  } from '../../utils';
import { RestLoanDocumentTemplatesRepository } from '../../infrastructure/repositories';
import PdfUtil from '../../utils/pdf_util';

const usePreviewTemplate = () => {
  const [loading, setLoading] = useState(false);

  const loanDocumentTemplatesRepository: LoanDocumentTemplatesRepository = RestLoanDocumentTemplatesRepository.getInstance();

  const previewTemplate = async (id: string) => {
    setLoading(true);
    const [preview, errorMessage] = await loanDocumentTemplatesRepository.previewTemplate(id);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      PdfUtil.openBlob(preview!);
    }
  }

  return {
    loading,
    previewTemplate
  };
};

export default usePreviewTemplate;