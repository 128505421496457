import { LoanMetadata } from "../../../../domain/models";
import { LoanMetadataEntity } from "../entities";

export default class LoanMetadataMapper {
  static toModel(entity: LoanMetadataEntity): LoanMetadata {
    return new LoanMetadata({
      secondsTakenForSignup: entity.seconds_taken_for_signup,
      secondsTakenForLoanCreation: entity.seconds_taken_for_loan_creation,
    });
  }
}