interface InputProps {
  onChange?: (event: any) => void;
  value: string;
  placeholder?: string;
  type?: string;
  errorMessage?: string | null;
  disabled?: boolean
  showCode?: boolean
}

const Input: React.FC<InputProps> = ({ value, onChange, placeholder, errorMessage, disabled = false, showCode = false }) => {
  const borderColor = errorMessage ? 'border-red-600' : 'border-slate-300';

  return (
    <div className="w-full flex flex-col flex-wrap space-y-2">
      <label className="font-bold text-sm opacity-65">{placeholder}</label>

      <iframe
        title="html-previewer"
        srcDoc={value}
        className="html-viewer w-full h-96 border shadow-xl rounded-lg bg-grey-100"
      />
      
      {
        showCode ? (
          <textarea
            className={`html-viewer-textarea w-full rounded-lg border text-sky-950 text-normal ${borderColor} p-2 h-96`}
            value={value}
            onChange={onChange}
            placeholder="Escribe o pega tu HTML aquí..."
            disabled={disabled}
          />
        ) : null
      }

      {errorMessage && <div className="px-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default Input;