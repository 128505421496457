import { ProviderContract } from "../../../../domain/models";
import { ProviderContractEntity } from "../entities";

export default class ProviderContractMapper {
  static toModel(entity: ProviderContractEntity): ProviderContract {
    return new ProviderContract({
      url: entity.url,
      signedContractUrl: entity.signed_contract_url
    });
  }
}