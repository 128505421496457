import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { Config } from "../../../domain/models";
import { ConfigMapper } from "./mappers";
import { ConfigRepository } from "../../../domain/repositories";
import { ConfigEntity } from "./entities";
import { LocalStorageTokenRepository } from "../";
import { UpdateConfigPayload } from "../../../domain/repositories/ConfigRepository";

export default class RestConfigRepository implements ConfigRepository {
  private static instance: RestConfigRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestConfigRepository();
    }
    return this.instance;
  }

  async getDefaultConfig(): Promise<[Config?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<ConfigEntity>(`${Environment.backendUrl}/admin/v1/config/default?t=${token}`);
      const config = ConfigMapper.toModel(response.data);

      return [config, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateDefaultConfig(payload: UpdateConfigPayload): Promise<[Config?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.put<ConfigEntity>(`${Environment.backendUrl}/admin/v1/config/update_default?t=${token}`, JsonUtil.removeBlankFields(payload));
      const config = ConfigMapper.toModel(response.data);

      return [config, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}