import { Loan, LoanApplication } from "../../../domain/models";
import { Money } from "../../../utils";

interface LoanApplicationDataContainerProps {
  children: React.ReactNode;
}

const LoanApplicationDataContainer: React.FC<LoanApplicationDataContainerProps> = ({ children }) => {
  return (
    <div className="flex flex-col space-y- bg-gray-100 p-4 rounded-lg">
      {children}
    </div>
  )
}

interface LoanApplicationDataProps {
  title: string;
  value?: any;
}

const LoanApplicationData: React.FC<LoanApplicationDataProps> = ({ title, value }) => {
  return (
    <div><strong>{title}:</strong> {value}</div>
  )
}

interface LoanApplicationInfoProps {
  loan: Loan;
}

const LoanApplicationInfo: React.FC<LoanApplicationInfoProps> = ({ loan }) => {
  const loanApplication: LoanApplication = loan.loanApplication;

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg">
      <h2 className="text-xl font-bold">Datos de la solicitud</h2>
      <LoanApplicationDataContainer>
        <LoanApplicationData title="Monto solicitado" value={Money.formatToCOP(loanApplication.amount)} />
        <LoanApplicationData title="Días calendario solicitados" value={loanApplication.days} />
        <LoanApplicationData title="Monto proyectado a pagar" value={Money.formatToCOP(loanApplication.amountToPay)} />
      </LoanApplicationDataContainer>

      <LoanApplicationDataContainer>
        <LoanApplicationData title="Departamento" value={loanApplication.state}  />
        <LoanApplicationData title="Ciudad" value={loanApplication.city}  />
        <LoanApplicationData title="Región" value={loanApplication.region ?? ''}  />
        <LoanApplicationData title="Dirección" value={loanApplication.address}  />
        <LoanApplicationData title="Estrato" value={loanApplication.layer}  />
        <LoanApplicationData title="Tiempo en la residencia" value={loanApplication.yearsInAddress}  />
        <LoanApplicationData title="Personas con las que vive" value={loanApplication.livesWith}  />
        <LoanApplicationData title="Número de personas que dependen de el" value={loanApplication.peopleInCharge}  />
        <LoanApplicationData title="Número de hijos" value={loanApplication.children}  />
        <LoanApplicationData title="Nivel Educativo" value={loanApplication.educationLevel}  />
        <LoanApplicationData title="Ingresos mensuales" value={Money.formatToCOP(loanApplication.monthlySalary)}  />
        <LoanApplicationData title="Ingresos mensuales a su hogar (Incluyendo los suyos)" value={Money.formatToCOP(loanApplication.totalMonthlyIncome)}  />
        <LoanApplicationData title="Tipo de vehiculo" value={loanApplication.vehicleType}  />
        <LoanApplicationData title="Placa (si aplica)" value={loanApplication.vehiclePlates ?? 'N/A'}  />
        <LoanApplicationData title="Ocupacion" value={loanApplication.occupation}  />
        <LoanApplicationData title="Empresa donde trabaja" value={loanApplication.company ?? 'N/A'}  />
        <LoanApplicationData title="Teléfono de la empresa" value={loanApplication.companyPhone ?? 'N/A'}  />
        <LoanApplicationData title="Tipo de dispositivo de la solicitud" value={loan.deviceInfo?.deviceType ?? 'N/A'} />
      </LoanApplicationDataContainer>
      <h2 className="text-xl font-bold">Referencia personal</h2>
      <LoanApplicationDataContainer>
        <LoanApplicationData title="Nombre" value={loanApplication.personalReferenceName}  />
        <LoanApplicationData title="Teléfono" value={loanApplication.personalReferencePhone}  />
        <LoanApplicationData title="Email" value={loanApplication.personalReferenceEmail}  />
        <LoanApplicationData title="Relación" value={loanApplication.personalReferenceRelation}  />
      </LoanApplicationDataContainer>
      <h2 className="text-xl font-bold">Referencia familiar</h2>
      <LoanApplicationDataContainer>
        <LoanApplicationData title="Nombre" value={loanApplication.familyReferenceName}  />
        <LoanApplicationData title="Teléfono" value={loanApplication.familyReferencePhone}  />
        <LoanApplicationData title="Email" value={loanApplication.familyReferenceEmail}  />
        <LoanApplicationData title="Relación" value={loanApplication.familyReferenceRelation}  />
      </LoanApplicationDataContainer>
      {
        loan.metadata ? (
          <>
            <h2 className="text-xl font-bold">Metadata</h2>
            <LoanApplicationDataContainer>
              <LoanApplicationData title="Tiempo que tardó el usuario registrándose" value={loan.metadata.secondsTakenForSignup ? `${loan.metadata.secondsTakenForSignup} segundos.` : 'N/A'} />
              <LoanApplicationData title="Tiempo que tardó el usuario creando el préstamo" value={loan.metadata.secondsTakenForLoanCreation ? `${loan.metadata.secondsTakenForLoanCreation} segundos.` : 'N/A'} />
            </LoanApplicationDataContainer>
          </>
        ) : null
      }
    </div>

  )
}

export default LoanApplicationInfo;