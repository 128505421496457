interface ILoanMetadata {
  secondsTakenForSignup: number;
  secondsTakenForLoanCreation: number;
}

export default class LoanMetadata {
  secondsTakenForSignup: number;
  secondsTakenForLoanCreation: number;

  constructor({
    secondsTakenForSignup, secondsTakenForLoanCreation,
  }: ILoanMetadata) {
    this.secondsTakenForSignup = secondsTakenForSignup;
    this.secondsTakenForLoanCreation = secondsTakenForLoanCreation;
  }
}