export enum LoanDocumentTemplateType {
  invoice = 'Paz y salvo',
  contract = 'Contrato'
}

interface ILoanDocumentTemplate {
  id: string;
  createdAt: Date;
  template: string;
  type: LoanDocumentTemplateType;
}

export default class LoanDocumentTemplate {
  id: string;
  createdAt: Date;
  template: string;
  type: LoanDocumentTemplateType;
  
  constructor({
    id, createdAt,
    template, type
  }: ILoanDocumentTemplate) {
    this.id = id;
    this.createdAt = createdAt;
    this.template = template;
    this.type = type;
  }
}