import { useState } from 'react';
import { UsersRepository } from '../../domain/repositories';
import { User } from '../../domain/models';
import { Alert } from '../../utils';
import { InputValidator, MoneyValue } from '../../infrastructure/inputs';
import { RestUsersRepository } from '../../infrastructure/repositories';

const useUpdateUserCreditCapacity = (user: User) => {
  const [amount, setAmount] = useState(MoneyValue.dirty(user?.creditCapacity?.amount ?? ""));
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const updateCreditCapacity = async (setUser: (user: User) => void) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [newUser, errorMessage] = await usersRepository.updateCreditCapacity(user.id, amount.getNumber()!);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setUser(newUser!);
    }
  }

  const validForm = (): boolean => {
    return InputValidator.valid([amount]);
  }

  return {
    amount, setAmount,
    submitted, updateCreditCapacity,
    loading
  };
};

export default useUpdateUserCreditCapacity;