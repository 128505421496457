import { PreviewedEmail, } from "../../../../domain/models";
import { PreviewedEmailEntity } from "../entities";

export default class PreviewedEmailMapper {
  static toModel(entity: PreviewedEmailEntity): PreviewedEmail {
    return new PreviewedEmail({
      subject: entity.subject,
      body: entity.body,
    })
  }
}