import { PageContainer } from "../components"
import { useEffect } from "react";
import { useBanks } from "../hooks";
import { NavLink } from "react-router-dom";
import Routes from "../../config/Routes";

const BanksPage = () => {
  const { loadBanks, banks } = useBanks();

  useEffect(() => {
    loadBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="flex flex-col space-y-4">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Bancos</h1>
          <h2 className="text-base">Listado de bancos</h2>
        </div>
      </div>

      <div className="flex justify-end">
        <NavLink to={Routes.NEW_BANK_PATH} className="text-md text-white bg-sky-600 hover:bg-sky-800 w-fit py-2 px-8 rounded-lg">
          Crear nuevo banco
        </NavLink>
      </div>

      <div className="overflow-scroll border border-gray-200 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">ID</th>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Nombre</th>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Código</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              banks.map((bank, index) => (
                <tr key={index}>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{bank.id}</td>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{bank.name}</td>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{bank.code}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

    </PageContainer>
  )
}

export default BanksPage;