import { PageContainer } from "../components"
import { useEffect } from "react";
import { useEventEmails } from "../hooks";
import { NavLink } from "react-router-dom";
import Routes from "../../config/Routes";

const EventEmailsPage = () => {
  const { eventEmails, loadEventEmails } = useEventEmails();

  useEffect(() => {
    loadEventEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className="flex flex-col space-y-4">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Emails para avisar un evento</h1>
          <h2 className="text-base">Listado de emails que se envían para avisar de un evento a los usuarios</h2>
        </div>
      </div>

      <div className="overflow-scroll border border-gray-200 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">ID</th>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Tipo de evento</th>
              <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              eventEmails.map((email, index) => (
                <tr key={index}>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{email.id}</td>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{email.eventType}</td>
                  <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                    <NavLink to={Routes.UPDATE_EVENT_EMAIL_PATH.replace(':id', email.id)} className="bg-sky-600 text-white hover:bg-sky-900 rounded-lg px-4 py-2">Editar</NavLink>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

    </PageContainer>
  )
}

export default EventEmailsPage;