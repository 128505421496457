export enum LoanStatusChangeEmailType {
  open = "Abierto",
  approved = "Aprobado",
  deposited = "Depositado",
  paid = "Pagado",
  rejected = "Rechazado"
}

interface ILoanStatusChangeEmail {
  id: string;
  createdAt: Date;
  subject: string;
  body: string;
  statusType: LoanStatusChangeEmailType;
}

export default class LoanStatusChangeEmail {
  id: string;
  createdAt: Date;
  subject: string;
  body: string;
  statusType: LoanStatusChangeEmailType;
  
  constructor({
    id, createdAt,
    subject, body,
    statusType
  }: ILoanStatusChangeEmail) {
    this.id = id;
    this.createdAt = createdAt;
    this.subject = subject;
    this.body = body;
    this.statusType = statusType;
  }
}