import { LoanStatusChangeEmail, } from "../../../../domain/models";
import { LoanStatusChangeEmailType } from "../../../../domain/models/loan_status_change_email";
import { LoanStatusChangeEmailEntity } from "../entities";

export default class LoanStatusChangeEmailMapper {
  static toModel(entity: LoanStatusChangeEmailEntity): LoanStatusChangeEmail {
    return new LoanStatusChangeEmail({
      id: entity.id,
      createdAt: entity.created_at,
      statusType: LoanStatusChangeEmailMapper.statusCdToStatusType(entity.status_cd),
      subject: entity.subject,
      body: entity.body,
    })
  }

  static statusCdToStatusType = (statusCd: number): LoanStatusChangeEmailType => {
    switch (statusCd) {
      case 0:
        return LoanStatusChangeEmailType.open;
      case 1:
        return LoanStatusChangeEmailType.approved;
      case 3:
        return LoanStatusChangeEmailType.deposited;
      case 4:
        return LoanStatusChangeEmailType.paid;
      case 5:
        return LoanStatusChangeEmailType.rejected;
      default:
        throw new Error(`Unknown status code: ${statusCd}`);
    }
  }
}