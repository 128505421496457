interface IDeviceInfo {
  deviceType: string;
}

export default class DeviceInfo {
  deviceType: string;

  constructor({ deviceType }: IDeviceInfo) {
    this.deviceType = deviceType;
  }
}
