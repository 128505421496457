import { useParams } from "react-router-dom";
import { LoansTable, PageContainer, Splash, UserBankAccount, UserCreditCapacity, UserPersonalInfo } from "../components"
import { useEffect } from "react";
import { DateUtil } from "../../utils";
import { useUsers } from "../hooks";

const UserDetailPage = () => {
  const { id } = useParams();
  const {
    loading,
    user,
    setUser,
    loadUser,
    userLoans,
    totalPages,
    page,
    loadUserLoans
  } = useUsers();
  useEffect(() => {
    loadUser(id!)
    loadUserLoans(id!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !user) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex-col space-y-10">
      <div className="flex-col space-y-5">
        <div className="flex flex-wrap flex-col space-y-2">
          <h1 className="text-4xl font-bold">Detalle de préstamo</h1>
          <h2 className="text-base"><strong>ID: </strong>{id}</h2>
          <h2 className="text-base"><strong>Fecha creación: </strong>{DateUtil.formatDate(new Date())}</h2>
        </div>
      </div>

      <div className="flex flex-col md:flex-row space-y-10 space-x-0 md:space-x-10 md:space-y-0">
        <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg">
          <UserPersonalInfo user={user} setUser={setUser} />
        </div>

        <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg">
          <UserBankAccount user={user} />
          <UserCreditCapacity user={user} setUser={setUser} />
        </div>
      </div>

      <div className="flex-col bg-white rounded-lg p-10 space-y-4">
        <h2 className="text-xl font-bold">Préstamos</h2>
        <LoansTable
          loans={userLoans}
          loading={loading}
          totalPages={totalPages}
          currentPage={page}
          onPageChange={(page: number) => loadUserLoans(user.id, page)}
        />
      </div>
    </PageContainer>
  )
}

export default UserDetailPage;