import { useUpdateUser } from "../../hooks";
import { Input, OptionInput, Button, Splash } from "../";
import { Date as DateInput, Email, HomePhone, MobilePhone, Selector, Text } from "../../../infrastructure/inputs";
import { Constants } from "../../../utils";
import { User } from "../../../domain/models";

interface UserPersonalInfoTableProps {
  children: React.ReactNode;
}

const UserPersonalInfoTable: React.FC<UserPersonalInfoTableProps> = ({ children }) => {
  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-scroll border border-gray-200 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

interface UserPersonalInfoTableEntryProps {
  title: string;
  children: React.ReactNode;
  dark?: boolean;
}

const UserPersonalInfoTableEntry: React.FC<UserPersonalInfoTableEntryProps> = ({ title, children, dark = false }) => {
  return (
    <tr className={dark ? 'bg-gray-100' : ''}>
      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">{title}</th>
      <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
        {children}
      </td>
    </tr>
  )
}

interface UserPersonalInfoProps {
  user: User;
  setUser: (user: User) => void;
}

const UserPersonalInfo: React.FC<UserPersonalInfoProps> = ({ user, setUser }) => {
  const {
    firstName, setFirstName,
    secondName, setSecondName,
    firstSurname, setFirstSurname,
    secondSurname, setSecondSurname,
    email, setEmail,
    birthDate, setBirthDate,
    mobilePhone, setMobilePhone,
    phone, setPhone,
    gender, setGender,
    maritalStatus, setMaritalStatus,
    documentNumber, setDocumentNumber,
    documentExpeditionDate, setDocumentExpeditionDate,
    documentType, setDocumentType,
    submitted, updateUser,
    loading
  } = useUpdateUser(user);

  if(loading) {
    return (
      <Splash />
    )
  }

  return (
    <form className="flex flex-col space-y-5 bg-white rounded-lg p-10" onSubmit={(event) => { event.preventDefault(); updateUser(setUser); }}>
      <h2 className="text-xl font-bold">Información personal</h2>
      <UserPersonalInfoTable>
        <UserPersonalInfoTableEntry title="Primer nombre">
          <Input
            className="p-3"
            value={firstName.value}
            errorMessage={submitted ? firstName.errorMessage() : null}
            onChange={(event) => { setFirstName(Text.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Segundo nombre">
          <Input
            className="p-3"
            value={secondName.value}
            errorMessage={submitted ? secondName.errorMessage() : null}
            onChange={(event) => { setSecondName(Text.dirtyAllowEmpty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Primer apellido">
          <Input
            className="p-3"
            value={firstSurname.value}
            errorMessage={submitted ? firstSurname.errorMessage() : null}
            onChange={(event) => { setFirstSurname(Text.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Segundo apellido">
          <Input
            className="p-3"
            value={secondSurname.value}
            errorMessage={submitted ? secondSurname.errorMessage() : null}
            onChange={(event) => { setSecondSurname(Text.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Fecha de nacimiento">
          <Input
            className="p-3"
            type="date"
            value={birthDate.value}
            errorMessage={submitted ? birthDate.errorMessage() : null}
            onChange={(event) => { setBirthDate(DateInput.dirty(event.target.value)) }}
            maxDate={new Date()}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Email">
          <Input
            className="p-3"
            type="email"
            value={email.value}
            errorMessage={submitted ? email.errorMessage() : null}
            onChange={(event) => { setEmail(Email.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Celular">
          <Input
            className="p-3"
            type="number"
            value={mobilePhone.value}
            errorMessage={submitted ? mobilePhone.errorMessage() : null}
            onChange={(event) => { setMobilePhone(MobilePhone.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Teléfono fijo">
          <Input
            className="p-3"
            type="number"
            value={phone.value}
            errorMessage={submitted ? phone.errorMessage() : null}
            onChange={(event) => { setPhone(HomePhone.dirtyAllowEmpty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Número de documento">
          <Input
            className="p-3"
            type="number"
            value={documentNumber.value}
            errorMessage={submitted ? documentNumber.errorMessage() : null}
            onChange={(event) => { setDocumentNumber(Text.dirty(event.target.value)) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Fecha de expedición del documento">
          <Input
            className="p-3"
            type="date"
            value={documentExpeditionDate.value}
            errorMessage={submitted ? documentExpeditionDate.errorMessage() : null}
            onChange={(event) => { setDocumentExpeditionDate(DateInput.dirty(event.target.value)) }}
            maxDate={new Date()}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Tipo de documento">
          <OptionInput
            className="text-black"
            options={Object.keys(Constants.DOCUMENT_TYPES).map((key) => ({ value: Constants.DOCUMENT_TYPES[key], label: key }))}
            selectedOption={documentType.value}
            errorMessage={submitted ? documentType.errorMessage() : null}
            onChange={(event) => { setDocumentType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.DOCUMENT_TYPES))) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Género">
          <OptionInput
            className="text-black"
            options={Object.keys(Constants.GENDER_TYPES).map((key) => ({ value: Constants.GENDER_TYPES[key], label: key }))}
            selectedOption={gender.value}
            errorMessage={submitted ? gender.errorMessage() : null}
            onChange={(event) => { setGender(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.GENDER_TYPES))) }}
          />
        </UserPersonalInfoTableEntry>

        <UserPersonalInfoTableEntry title="Estado civil">
          <OptionInput
            options={Object.keys(Constants.CIVIL_STATUS_TYPES).map((key) => ({ value: Constants.CIVIL_STATUS_TYPES[key], label: key }))}
            selectedOption={maritalStatus.value}
            className=''
            errorMessage={submitted ? maritalStatus.errorMessage() : null}
            onChange={(event) => { setMaritalStatus(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.CIVIL_STATUS_TYPES))) }}
          />
        </UserPersonalInfoTableEntry>
      </UserPersonalInfoTable>

      <div className="flex w-full items-center justify-center">
        <Button className="text-md py-2 px-4 w-fit" disabled={false} text="Guardar" type="submit" />
      </div>
    </form>
  )
}

export default UserPersonalInfo;