import { useState } from 'react';
import { EventEmailsRepository } from '../../domain/repositories';
import { Alert, Constants } from '../../utils';
import { RestEventEmailsRepository } from '../../infrastructure/repositories';
import { InputValidator, Text } from '../../infrastructure/inputs';
import { EventEmailType } from '../../domain/models/event_email';

const useUpdateEventEmail = () => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(Text.dirty(''));
  const [body, setBody] = useState(Text.dirty(''));
  const [eventType, setEventType] = useState<EventEmailType | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);

  const eventEmailsRepository: EventEmailsRepository = RestEventEmailsRepository.getInstance();

  const loadEventEmail = async (id: string) => {
    setLoading(true);
    const [eventEmail, errorMessage] = await eventEmailsRepository.getEmail(id);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setSubject(Text.dirty(eventEmail!.subject));
      setBody(Text.dirty(eventEmail!.body));
      setEventType(eventEmail?.eventType);
    }
  }

  const updateEventEmail = async (id: string) => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [eventEmail, errorMessage] = await eventEmailsRepository.updateEmail(id, subject.value, body.value);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      Alert.showSuccess(Constants.UPDATED_EMAIL_CORRECTLY_MSG);
      setSubject(Text.dirty(eventEmail!.subject));
      setBody(Text.dirty(eventEmail!.body));
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([subject, subject]);
  }

  return {
    loading,
    subject, setSubject,
    body, setBody,
    submitted, updateEventEmail,
    loadEventEmail, eventType
  };
};

export default useUpdateEventEmail;