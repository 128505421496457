import { Loan } from "../../../domain/models";
import { Table } from "../";
import Routes from "../../../config/Routes";
import { NavLink } from "react-router-dom";
import { Color, DateUtil, Money } from "../../../utils";

interface LoansTableProps {
  loans: Loan[] | undefined;
  loading: boolean;
  totalPages: number | undefined;
  currentPage: number | undefined;
  onPageChange: (page: number) => void;
}

const LoansTable: React.FC<LoansTableProps> = ({ loans, loading, totalPages, currentPage, onPageChange }) => {
  const columnNames = [
    'Solicitante',
    'Consecutivo',
    'Fecha creación',
    'Fecha estimada de pago',
    'Cantidad solicitada',
    'Cantidad aprobada',
    'Días en mora',
    'Estado',
    'Acciones'
  ];
  let rows: React.ReactNode[][] = [];
  const EMPTY_ROW_VALUE = '-';

  if (loans?.length === 0) {
    return (
      <div className="flex flex-wrap flex-col justify-center items-center mt-5 md:mt-20">
        <h2 className="font-bold text-md opacity-50 text-center">Por el momento no hay préstamos</h2>
      </div>
    )
  }
  
  loans?.forEach((loan) => {
    rows.push([
      <div>
        <NavLink to={Routes.USER_DETAIL_PATH.replace(':id', loan.user.id)} className="text-xs text-sky-600 hover:text-sky-800">
          {loan.user.fullName}
        </NavLink>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{loan.consecutive}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{DateUtil.shortDate(loan.createdAt)}</h2>
      </div>,
      <div>
        <h2 className={`text-xs ${loan.inArrears() ? 'text-red-600' : 'text-gray-800'} dark:text-white`}>{loan.estimatedPaymentDate ? DateUtil.shortDate(loan.estimatedPaymentDate) : EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{Money.formatToCOP(loan.loanApplication.amount)}</h2>
      </div>,
      <div>
        <h2 className="text-xs text-gray-800 dark:text-white">{loan.amountApproved ? Money.formatToCOP(loan.amountApproved) : EMPTY_ROW_VALUE}</h2>
      </div>,
      <div>
        <h2 className={`text-xs ${loan.daysInArrears === 0 ? 'text-gray-800' : 'text-red-500'} dark:text-white`}>{loan.daysInArrears}</h2>
      </div>,
      <div className={`text-xs  px-3 py-2 font-normal rounded-full text-center ${Color.loanStatusToBgColor(loan.status)}`}>
        {loan.status}
      </div>,
      <NavLink to={Routes.LOAN_DETAIL_PATH.replace(':id', loan.id)} className="text-xs bg-sky-600 hover:bg-sky-800 py-2 px-4 rounded-full text-white font-normal">Ver</NavLink>
    ])
  });

  return(
    <div className="w-full">
      <Table
        loading={loading}
        columnNames={columnNames}
        rows={rows}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange} />
    </div>
  )
}

export default LoansTable;