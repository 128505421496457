import { useState } from "react";
import { Purpose } from "../../domain/models";
import { Alert } from "../../utils";
import { RestPurposesRepository } from "../../infrastructure/repositories";
import { PurposesRepository } from "../../domain/repositories";

const usePurposes = () => {
  const [loading, setLoading] = useState(false);
  const [purposes, setPurposes] = useState<Purpose[]>([]);

  const purposesRepository: PurposesRepository = RestPurposesRepository.getInstance();

  const loadPurposes = async () => {
    setLoading(true);
    const [obtainedPurposes, errorMessage] = await purposesRepository.getPurposes();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setPurposes(obtainedPurposes!);
    }
    setLoading(false);
  }

  return {
    loading, setLoading,
    purposes, setPurposes,
    loadPurposes
  };
};

export default usePurposes;