interface IFraudModelInfo {
  fraudulent: boolean;
  reason?: string;

}
interface IRiskModelInfo {
  phoneRisky: boolean;
  phoneReason?: string;
  emailRisky: boolean;
  emailReason?: string;
}

interface IModelInfo {
  fraud: IFraudModelInfo;
  risk: IRiskModelInfo;
  paymentPrediction: number;
  userPreviousPaidLoansCount: number;
  userInArrears: boolean;
  userPreviousPaidLegacyLoansCount: number;
  userInArrearsInLegacyApp: boolean;
}

export default class ModelInfo {
  fraud: IFraudModelInfo;
  risk: IRiskModelInfo;
  paymentPrediction: number;
  userPreviousPaidLoansCount: number;
  userInArrears: boolean;
  userPreviousPaidLegacyLoansCount: number;
  userInArrearsInLegacyApp: boolean;

  constructor({
    fraud, risk,
    paymentPrediction, userPreviousPaidLoansCount,
    userInArrears, userPreviousPaidLegacyLoansCount,
    userInArrearsInLegacyApp
  }: IModelInfo) {
    this.fraud = fraud;
    this.risk = risk;
    this.paymentPrediction = paymentPrediction;
    this.userPreviousPaidLoansCount = userPreviousPaidLoansCount;
    this.userInArrears = userInArrears;
    this.userPreviousPaidLegacyLoansCount = userPreviousPaidLegacyLoansCount;
    this.userInArrearsInLegacyApp = userInArrearsInLegacyApp;
  }
}