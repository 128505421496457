import { useState } from 'react';
import { LoansRepository } from '../../domain/repositories';
import { Alert } from '../../utils';
import { RestLoansRepository } from '../../infrastructure/repositories';
import PdfUtil from '../../utils/pdf_util';

const useLoanInvoice = (loanId: string) => {
  const [loading, setLoading] = useState(false);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadInvoice = async () => {
    setLoading(true);
    const [invoice, errorMessage] = await loansRepository.getInvoice(loanId);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      PdfUtil.openBlob(invoice!);
    }
  }

  return {
    loading,
    loadInvoice
  };
};

export default useLoanInvoice;