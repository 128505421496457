interface ICreditCapacity {
  amount: number;
  used: number;
}

export default class CreditCapacity {
  amount: number;
  used: number;

  constructor({ amount, used }: ICreditCapacity) {
    this.amount = amount;
    this.used = used;
  }

  get available() {
    return this.amount - this.used;
  }
}